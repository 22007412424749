import { Theme } from 'app'
import { React, StyleSheet, View, Text, CenterWrapper, onUpdate, useState } from 'lib'

import image_app from '../../assets/how_to_app.png'
import image_walk from '../../assets/how_to_walk.png'
import image_notification from '../../assets/how_to_notification.png'
import image_alerts from '../../assets/how_to_alerts.png'

const heading = 'How Becon Works'

const items = [
  {
    image: image_app,
    body: 'Open the app, enter your destination, activate Becon to protect your journey and... off you go!',
  },
  {
    image: image_walk,
    body: "Becon's smart technology monitors the speed, motion & location of your device until you reach your destination.",
  },
  {
    image: image_notification,
    body: 'If Becon detects unusual activity from your device, the app automatically checks in on you with a timed notification.',
  },
  {
    image: image_alerts,
    body: 'If you’re unable to confirm your safety by end of the timer, alerts containing your precise location are sent to your Key Contacts.',
  },
]

const HowBeconWorks: React.FC = () => {
  const [sectionHeight, setSectionHeight] = useState(null)
  const { width } = Theme.hooks.size()

  onUpdate(() => {
    const finalHeight = document.getElementById('#how-it-works')?.clientHeight + Theme.spacing(6)
    if (finalHeight && finalHeight != sectionHeight) {
      setSectionHeight(finalHeight)
    }
  }, [width])

  return (
    <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper} id='#how-it-works'>
      <Text
        text={heading}
        variant={['h4', 'textCenter', 'white']}
      />
      <View style={styles.itemGrid}>
        {items.map(({ image, body }, key) => (
          <View variant={['flex', 'column', 'alignCenter']} style={styles.buttons} key={key}>
            <View
              style={[
                styles.image,
                {
                  backgroundImage: `url(${image})`,
                },
              ]}
            />
            <View variant={['flex', 'column', 'alignCenter']}>
              <Text
                text={body}
                style={styles.text}
                variant={['p3', 'textCenter', 'marginTop:2', 'white']}
              />
            </View>
          </View>
        ))}
      </View>
    </CenterWrapper>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    position: 'relative',
    backgroundColor: Theme.colors.greyscale1,
  },
  innerWrapper: {
    marginTop: Theme.spacing(12),
    marginBottom: Theme.spacing(12),
    display: 'flex',
    flexWrap: 'wrap',
    gap: Theme.spacing(3),
    ...Theme.justifyCenter,
    ...Theme.alignCenter,
  },
  itemGrid: {
    display: 'grid',
    gap: Theme.spacing(2),

    [Theme.media.up('mid')]: {
      marginTop: Theme.spacing(5),
      marginBottom: Theme.spacing(5),
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(3),
      marginBottom: Theme.spacing(3),
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [Theme.media.down('small')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  buttons: {
    maxWidth: 320,
    justifyContent: 'space-between',
    padding: Theme.spacing(3),
    borderRadius: Theme.spacing(2.25),
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  text: {
    maxWidth: '80%',
  },
  image: {
    width: 230,
    height: 176,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
  },
})

export default HowBeconWorks
